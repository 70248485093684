import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

// add all reducers here
import counterReducer from "./pages/counter/redux";

const store = configureStore({
  reducer: {
    counter: counterReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;
