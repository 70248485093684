import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { Counter } from "pages";
// import Main from "component/Main/Main";
import Api from "services/Api";
import { NavLink, useHistory } from "react-router-dom";

import Main from "./component/Main/Main"
// import Main from "component/Main/Main";
// import { authActions } from "./component/store";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
function App(props) {
  // const {token}=useSelector(state=>state.auth);
  // const dispatch=useDispatch();
  let history = useHistory();

  let params=new URL(window.location);
  // const [token,setToken]=useState(localStorage.getItem("token")?localStorage.getItem("token"):null)
  // setTimeout(() => {
  // }, 1000);
  let a1=params.search.slice(7,20);
  let a2=params.search.slice(25,33);
  useEffect(() =>{
    if(localStorage.getItem("token")){
      return ;
    }
  
    Api.get("/auth/signin")
    .params({token:a1,uid:a2})
    .send((response,error) =>{
if(response?.accessToken!==undefined)
 { 
  localStorage.setItem("token",response?.accessToken)
    // setToken(response?.accessToken)
    // props.history.push("/")
    window.location='/'


 }else{
 }
      // if(error!==undefined)
      // {
      //   window.open("https://www.brigosha.com","_self")
      //   return;
      // }
     
    })
  },[localStorage.getItem("token")])
  useEffect(() => {
    if (localStorage.getItem("token")) {
      Api.header(localStorage.getItem("token"));
    }
  }, [localStorage.getItem("token")])




  return (
    <>
    <Router>
      <Switch>
        <Route  path="/">
         
<Main></Main>          
        </Route>
      </Switch>
    </Router></>
  );
}

export default App;
