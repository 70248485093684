import { Col, Row } from "antd"
import Associates from "component/Associates/Associates"
import Dashboard from "component/Dashboard/Dashboard"
import Departments from "component/Departments/Departments"
import Header from "component/Sidebar/Header"
import Sidebar from "component/Sidebar/Sidebar"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"



function Main(props) {
    // console.log("andha sala",props?.token);
    return (
        
        <Router>
            <Switch>
                <Row >
                    <Col span={3}><Sidebar/></Col>
                    <Col span={21} >

                        <Row style={{ height: '120px'}}>
                            <Col span={8} offset={16} ><Header /></Col>
                        </Row>
                        <Row style={{ backgroundColor:'#F7F7F7'}}>
                            <Route exact  path="/" >
                                <Dashboard token={props?.token}  name={"praveen"}/>
                                {/* {console.log("andha sala", token,props)} */}
                            </Route>
                            <Route exact path='/departments' >
                                <Departments />
                            </Route>
                            <Route exact path='/associates'>
                                <Associates />
                            </Route>
                        </Row>
                    </Col>
                </Row>
            </Switch>
        </Router>
        
    )
}
export default Main