import { Column } from '@ant-design/plots'
import { Col, Row,Card } from 'antd';
import BarGraph from 'component/BarGraph';
import "../../App.css"


function  Graphview(props)
{
  // console.log("mila mila",props)
 if(props?.data?.length!==0){
    return(
    
  <Row className='scroll' style={{display:'flex', gap:60 ,maxHeight:'600px', overflowY:"scroll", width:'100%',}}>
  { props?.data?.map((val,index)=>{
 let data=[{y:val?.one,x:"😒"},{y:val?.two, x:"😧"},{y:val?.three,x:'🤨'},{y:val?.four,x:"😃"},{y:val?.five,x:"😍"}]
 let title={y:"Number of Associates", x:"Rating"};
 let question=val?.question

//  console.log("zzzzzz",data)
 
            return(
          <Col span={11}>
<BarGraph data={data} graphHeight={350} barColor="#5E8CFC" question={question} title={title} date={props?.date} deptId={props?.deptId}></BarGraph>
</Col>
            )    
                
               
         })}

      </Row>
     

      
       
    )}
    else{
      return(
        <Row>No Data Found</Row>
      )
    }
}
export default Graphview;