import React, { Fragment, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Input, Table, Row, Col, Card, Modal } from 'antd';

function Recommendations(props) {
    // console.log("yyyy", props?.data)

    const [recData, setRecData] = useState()
    const [recModal, setRecModal] = useState(false)


    if (props?.data?.length !== 0) {
        return (
            <Row style={{ display: 'flex', flexDirection: 'column', gap: 20, width: '100%' }}>

                {
                    props?.data?.map((val, index) => {
                        return (

                            <Row style={{ width: '100%', minHeight: '220px', background: 'white', padding: '1rem' }}
                                onClick={() => { setRecModal(true); setRecData(val) }}>
                                <Row style={{ display: 'flex', width: '100%' }}>

                                    <Col span={17} >
                                        <div style={{ display: 'flex', flexDirection: 'row', gap: "10px" }}>
                                            <div style={{ font: 'normal normal bold 16px/21px Roboto' }}>{val?.employeeName ? val?.employeeName : "--name--"}</div>
                                            <div>{val?.employeeEmail ? val?.employeeEmail : "--email--"}</div>
                                            <div>{val?.employeePhoneNumber ? val?.employeePhoneNumber : "--phone--"}</div>
                                        </div>


                                    </Col>
                                    <Col span={7} style={{ display: 'flex', gap: '5px' }}>
                                        <div style={{ font: "normal normal normal 16px/21px Roboto" }}>{val?.teamName ? val?.teamName : "--team--"}</div>
                                        <div style={{ font: 'normal normal bold 16px/21px Roboto' }}>{val?.managerName ? val?.managerName : "--manager--"}</div>
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%', marginTop: '-10px' }}>
                                    <Col>
                                        <div>{val?.employeeId ? val?.employeeId : "--id--"}</div>
                                    </Col>
                                </Row>

                                <Row className='scroll'  style={{ width: '100%', overflowY:'scroll',maxHeight:'120px', marginTop:10 }}>
                                    <Col>
                                        <p>{val?.value ? val?.value : "---description---"}

                                        </p>
                                    </Col>

                                </Row>

                            </Row>

                        )
                    })
                }

                <Modal visible={recModal} onCancel={() => { setRecModal(false) }} footer={false} closable style={{ minWidth: '700px' }} >
                    <Row style={{ minHeight: "200px", }}>
                        <Row style={{ display: 'flex', width: '100%' }}>


                            <Col span={6} offset={0}  >
                                <div style={{ font: 'normal normal bold 16px/21px Roboto' }}>{recData?.employeeName}</div>

                            </Col>
                            <Col span={11} style={{ display: 'flex', gap: 5, }} >

                                <div>{recData?.employeeEmail}</div>
                                <div>{recData?.employeePhoneNumber}</div>

                            </Col>
                            <Col span={4} offset={3}>

                                <div style={{ font: 'normal normal bold 16px/21px Roboto' }}>
                                    {
                                        props?.deptRes?.map((obj,index) =>{
                                            if(obj.department == props?.deptData){

                                                return(
                                                    <div>{obj?.department}</div>

                                                )
                                                
                                            }

                                        })
                                    }
                                </div>



                            </Col>

                        </Row>


                        <Row style={{ width: '100%', marginTop: "0px" }}>
                            <Col span={4}>
                                <div>{recData?.employeeId}</div>
                            </Col>
                            <Col span={12} offset={8} style={{ display: 'flex', gap: '5px' }}>
                                <div style={{ font: 'normal normal bold 16px/21px Roboto' }}>{recData?.managerName}</div>
                                <div>{recData?.teamName}</div>
                            </Col>
                        </Row>

                        <Row className='scroll' style={{ width: '100%', maxHeight:'400px',overflowY:'scroll', marginTop:10 }}>
                            <Col>
                                <p>{recData?.value}

                                </p>
                            </Col>

                        </Row>


                    </Row>

                </Modal>

            </Row>

        )
    }
    else {
        return (
            <Row>
                No Data Found
            </Row>
        )
    }

}

export default Recommendations