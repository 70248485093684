 import { Modal, Table, Tooltip } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React from "react";
 import {useEffect,useState} from  "react";
import Api from "services/Api";
 import "./BarGraph.css";
 function BarGraph({data, graphHeight, TooltipComponent=null, title,setState=null, barColor, tick=null, question, deptId, date}) {
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [mxVal, setMaxVal] =useState(Math.max.apply(Math, data.map(item=>item.y)));
  const [modal,setModal]=useState(false)
  const [index,setIndex]=useState();
  const [loader,setLoader]=useState(false)
const [ratingData,setRatingData]=useState([])
const emoji=["😒","😧",'🤨',"😃","😍"];



    const handler=(index)=>{
        setIndex(index);
        setModal(true);
        setLoader(true);
        let a=date?.split("-");
        let data={month:a[1],year:a[0],departmentId:deptId,questionIs:question,ratingValue:index+1}

  Api.get("/dashboard/graph/employees/").params(data).send((res)=>{
    setRatingData(res);
    setLoader(false)
  })

    }

    const columns = [
        {
            title: 'Associate',
            dataIndex: 'associate',
            key: 'associate',
            render: (text, record) => {
                return (
                    <div >
                        <p style={{ font: 'normal normal medium 16px/21px Roboto', color: '#000000', opacity: '1' }}>
                            {record?.employeeName}</p>
                        {/* <p style={{ font: 'normal normal normal 12px/16px Roboto', color: '#000000', opacity: '1' }}>
                            {record?.empyId}</p> */}
                    </div>
                )
            }

        },
        {
            title: 'Team',
            dataIndex: 'team',
            key: "team",
            render: (text, record) => {

                return (
                    <div 
                        style={{ font: 'normal normal normal 12px/16px Roboto', color: '#000000', opacity: '1' }}>
                        {record?.teamName}</div>
                )
            }
        },
        {
            title: 'Manager',
            dataIndex: 'manager',
            key: "manager",
            render: (text, record) => {

                return (
                    <div 
                        style={{ font: 'normal normal normal 12px/16px Roboto', color: '#000000', opacity: '1' }}>
                        {record?.managerName}</div>
                )
            }
        },]




  useEffect(()=>{
    setMaxVal(Math.max.apply(Math, data.map(item=>item.y)));
  }, data);

let graphlength=Math.ceil(Math.ceil(mxVal/4)/5)*20;
  const oneBlock = parseFloat(graphlength/4).toFixed(4)
  data.forEach((item)=>{
      item.y = item.y?item.y:0
  })
  if (mxVal>0){
    return (
    <div style={{width:"100%", backgroundColor:'white',height:'550px',boxShadow: "0px 3px 6px #00000029"}}>
       <div style={{ font: " 16px / 24px Roboto",fontWeight:'500', padding:"10px 10px 10px 40px",width:'100%'}}><i>{ question}</i></div>
    <div style={{display:'flex',width:'100%'}}>
    <div  style={{ width:40}}><div style={{ transform: 'rotate(270deg)', marginTop: 10, width: 400, marginLeft: -180  }}>{title?.y}</div></div>
   <div style={{ display:'flex',flexDirection:'row',height:"100%", width:"100%", alignItems:"flex-end", backgroundColor:"white", paddingTop:10, paddingLeft:10,paddingBottom:10,}}>
            <div style={{paddingRight:10, borderRightWidth:0.5, borderColor:"#636363", }}>
                {Array(5).fill().map((item, index)=>{
                   if(index===0)
                   {
                        return(
                            <div style={{height:25,display:"flex", justifyContent:"flex-end", alignItems:"flex-end",}}>
                            
                            <div >{ oneBlock<1?parseFloat(oneBlock*(4-index)).toFixed(1): Math.round(oneBlock*(4-index))} {tick?tick:''}</div>
                        </div>
                        )
                   }
                   else{
                   return(
                        <div style={{height:Math.floor(graphHeight/4),display:"flex", justifyContent:"flex-end", alignItems:"flex-end",}}>
                            
                            <div >{ oneBlock<1?parseFloat(oneBlock*(4-index)).toFixed(1): Math.round(oneBlock*(4-index))} {tick?tick:''}</div>
                        </div>
                    )}
                
                })}
                <div style={{height:'30px'}}></div>
            </div>
            <div style={{position:"relative" , width:"100%",} }>
                <div  style={{display:'flex',flexDirection:'row', height:graphHeight+40 , alignItems:'flex-end',}}>
                    {
                            data?.map((item,index)=>{
                               let ht=Math.floor(item.y*graphHeight/graphlength);
                            return(
                               
                                <div id={index} onClick={()=>{handler(index)}} className="ratingHeight" style={{width:'100%', display:"flex", alignItems:"center",height:"100%",flexDirection:"column", paddingLeft:'5px',paddingRight:'5px', }}>
                                    <div underlayColor={'#E6E6E6'}  
                                    style={{  height:graphHeight, display:"flex", justifyContent:"flex-end",alignItems:'flex-end'}}>
                                       <Tooltip placement="right" title={item?.y}>  <div style={{backgroundColor:barColor, height:ht, width:20, borderRadius:'4px',cursor:'pointer'}}>
                                        </div> </Tooltip>
                                    </div>
                                    <div style={{fontSize:24 }}> {item.x} </div>
                                </div>
                               
                               
                            )
                        })
                    }
                   
                </div>
                <div style={{position:"absolute", borderTopWidth:0.5, borderColor:"#636363", width:"100%" ,display:'flex' ,bottom:20,}}>
                        
                        </div>
                {isTooltipVisible?<div style={{position:"absolute", top:0, left:10}}>{TooltipComponent}</div>:null}
            </div>
        </div> 
       
   </div> 
   <div style={{width:'100%',display:'flex', justifyContent:'center' }}>{title.x}</div>
    <Modal open={modal} onCancel={()=>{setModal(false)}} footer={false} style={{maxHeight:'700px'}}>
                    <div>Rating {emoji[index]}</div>
                    <Table
                    columns={columns}
                    dataSource={ratingData}
                    style={{maxHeight:""}}
                    loading={loader}
                    scroll={{ y: 500 }}
                    ></Table>
    </Modal>


   </div>
  
  

 
      )
  }else{
          return <div>No Data </div>
    }
}
export default BarGraph;


{/* <div style={{display:"flex",alignItems:"center",marginLeft:"-200px"}}>
<div className="titley" style={{backgroundColor:"red",display:"flex",width:"90%"}}>{title.y}</div>

</div>



<div style={{display:'flex', justifyContent:'center'}}>{title.x}</div></div> */}
