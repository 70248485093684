import { Col, Modal, Row, Table, Input, DatePicker, Spin, Typography } from "antd";
import { AudioOutlined, LeftOutlined, RightOutlined, EyeOutlined, PicCenterOutlined } from '@ant-design/icons';
import { useState } from "react";
import moment from "moment";
import 'antd/dist/antd.min.css'
import Item from "antd/lib/list/Item";
import Api from "services/Api";
import "../../App.css"
function Associateview(props) {
    // console.log('uuu', props?.data)
    const [viewModal, setViewModal] = useState(false);
    const [viewData, setViewData] = useState({})
    
    const [feedBackDetailsModal, setViewFeedbackModal] = useState(false)
    const [feedBackDetailsData, setFeedbackDetailsData] = useState([])
    const [feedbackId,setfeedbackId]=useState(null);
    const time= moment().format("YYYY-MM");
    const [dateR, setDateR] = useState(time)
    const [feedbackDate,setFeedbackDate]=useState(time);
    const [recommendation,setRecommendation]=useState({})
    const [feedbackData,setFeedbackData]=useState([])
    const [offLocation,setOffLocation]=useState(null);
    const [loader,setLoader]=useState(false)
    const { Search } = Input;

    const remarkHandler = (id, dept) => {
        let a = props?.date?.split("-");

        // console.log("zzz", { month: a[1], year: a[0], department: dept, empId: id })
        Api.get("/associates/remarkDetail/").params({ month: a[1], year: a[0], department: dept, empId: id }).send((res) => {
            // console.log("zzzz", res)
            setRecommendation(res);


        })
    }

        const feedbackHandler=(id, date)=>{
            setLoader(true)
            let a=date.split("-");
            Api.get("/departments/feedBackDetail/").params({month:a[1],year:a[0],empId:id}).send((res)=>{
                setLoader(false)
                setFeedbackData(res?.responseData);
                setOffLocation(res?.officeLocation);
            })
            setViewFeedbackModal(true);
        }

    const columns = [
        {
            title: "Associate",
            // width: 150,
            align: 'center',
            dataIndex: 'associate',
            key: 'associate',
            render: (text, record) => {
                return (
                    <div onClick={() => {   setFeedbackDetailsData(record);setfeedbackId(record?.id);feedbackHandler(record?.id,feedbackDate)   }}>
                        <Typography.Text ellipsis={{tooltip:true}} style={{font: 'normal normal medium 16px/21px Roboto', maxWidth:"140px",}}>{record?.employeeEmail}</Typography.Text>
                       
                        <p>{record?.employeeId}</p>
                    </div>
                )
            }

        },
        {
            title: 'Contact Details',
            dataIndex: 'contact_details',
            // width: 150,
            align: 'center',
            key: 'contact',
            render: (text, record) => {
                return (
                    <div onClick={() => {   setFeedbackDetailsData(record);setfeedbackId(record?.id);feedbackHandler(record?.id,feedbackDate)   }}>
        
                        <Typography.Text ellipsis={{tooltip:true}} style={{font: 'normal normal medium 16px/21px Roboto', maxWidth:"140px",}}>{record?.employeeEmail}</Typography.Text>
                        <p>{record?.employeePhoneNumber}</p></div>
                )
            }


        },

        {
            title: 'Designation',
            dataIndex: 'designation',
            // width: 100,
            align: 'center',
            key: "designation",
            render: (text, record) => {

                return (
                    <div onClick={() => {   setFeedbackDetailsData(record);setfeedbackId(record?.id);feedbackHandler(record?.id,feedbackDate)   }}>{record?.employeeDesignation}</div>
                )
            }

        },
        {
            title: 'Department',
            dataIndex: 'department',
            // width: 100,
            align: 'center',
            key: "department",
            render: (text, record) => {

                return (
                    <div onClick={() => {   setFeedbackDetailsData(record);setfeedbackId(record?.id);feedbackHandler(record?.id,feedbackDate)   }}>{record?.employeeDepartment}</div>
                )
            }
        },
        {
            title: 'Team',
            dataIndex: 'team',
            // width: 80,
            align: 'center',
            key: "team",
            render: (text, record) => {

                return (
                    <div onClick={() => {   setFeedbackDetailsData(record);setfeedbackId(record?.id);feedbackHandler(record?.id,feedbackDate)   }}>{record?.teamName}</div>
                )
            }
        },
        {
            title: 'Manager',
            dataIndex: 'manager',
            // width: 80,
            align: 'center',
            key: "manager",
            render: (text, record) => {

                return (
                    <div onClick={() => {   setFeedbackDetailsData(record);setfeedbackId(record?.id);feedbackHandler(record?.id,feedbackDate)   }}>{record?.managerName}</div>
                )
            }
        },
        {
            title: 'Recommendations',
            dataIndex: 'recommendations',
            // width: 100,
            align: 'center',
            key: 'recommendation',
            render: (text, record) => {

                return (
                    <div  onClick={() => {  remarkHandler(record?.id,record?.employeeDepartment); setViewModal(true); setViewData(record) }}>
                        View Remarks <EyeOutlined />
                    </div>
                )
            }
        },
    ];



    return (
        <Row style={{ width: '100%' }}>
            <Row style={{ width: '100%', display:'flex', justifyContent:'end' }}>
              
                    <Search placeholder="search" onChange={(val) => { props?.setSearch(val?.target?.value) }} style={{ width: 250, marginLeft: '10rem' }} />
               
            </Row>
            <Row >
                <Table
                    columns={columns}
                    dataSource={props?.data}
                     rowClassName='tableRow'
                    scroll={{ y: 500, x:600 }}
        //             pagination={{
        //                 total:props?.data?.length,
        //                 showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} members`
        //   }}
            pagination={props?.tableParams?.pagination}
            loading={props?.loading}
            onChange={props?.handleTableChange}
          >
                   
                </Table>

            </Row>

            <Modal visible={viewModal} onCancel={() => { setViewModal(false) }} footer={false} closable style={{ minWidth: '700px' }}>

                <Row >
                    <Row style={{ width: '100%' }}>
                        <Col span={6} offset={0}  >
                            <div className="empName">{viewData?.employeeName}</div>

                        </Col>
                        <Col span={11} style={{ display: 'flex', gap: 5, }} >

                            <p>{viewData?.employeeEmail}</p>
                            <p>{viewData?.employeePhoneNumber}</p>

                        </Col>
                        <Col span={4} offset={3}>

                            <div className="empName">{viewData?.employeeDepartment}</div>

                        </Col>

                    </Row>
                    <Row style={{ width: "100%" }}>
                        <Col span={4}  >
                            <div>{viewData?.employeeId}</div>
                        </Col>
                        <Col span={13} offset={7} style={{ display: 'flex',gap:7 }} >
                            <div className="empName" style={{width:'100%'}}>{viewData?.managerName}</div>
                            <div style={{width:'100%'}}>{viewData?.teamName}</div>
                        </Col>

                    </Row>
                    <Row style={{ marginTop: "0px" }}>

                        <div style={{ marginTop:'1rem' }}>{recommendation?.value}</div>


                    </Row>


                </Row>

            </Modal>



            <Modal visible={feedBackDetailsModal} onCancel={() => { setViewFeedbackModal(false) }} footer={false} closable style={{minWidth:'700px'}}>
            <Row >

                    <Row style={{ width: "100%" }}> 
                        <Col span={11} style={{}}>
                            <div className='associateName'>{feedBackDetailsData?.employeeName}</div>
                            <div>{feedBackDetailsData?.employeeId}</div>
                            <div className='associateName'>{feedBackDetailsData?.employeeDesignation}</div>

                        </Col>


    <Col span={11} offset={1} style={{ display: 'flex',gap:5 }} >
                       
                       <div style={{display:'flex',flexDirection:'column',gap:5, minWidth:'120px'}}>
                             <div>Department:</div>
                             <div>Manager:</div>
                             <div>Team Name:</div>
                             <div>Office Location:</div>
                             <div>Email ID:</div>
                             <div>Mobile Number:</div>
                      </div>
                      <div style={{display:'flex',flexDirection:'column',gap:5}}>                     
                         <div className='associateName'>{feedBackDetailsData?.employeeDepartment?feedBackDetailsData?.employeeDepartment:"------"}</div>
                         <div className='associateName'>{feedBackDetailsData?.managerName?feedBackDetailsData?.managerName:"-----"}</div>
                         <div className='associateName'>{feedBackDetailsData?.teamName?feedBackDetailsData?.teamName:"-----"}</div>
                         <div className='associateName'>{offLocation!==null?offLocation:"-----"}</div>
                         <div className='associateName'>{feedBackDetailsData?.employeeEmail?feedBackDetailsData?.employeeEmail:"-----"}</div>
                         <div className='associateName'>{feedBackDetailsData?.employeePhoneNumber?feedBackDetailsData?.employeePhoneNumber:"-----"}</div>
                        
                      </div>
                                   
                     </Col>

    
                    </Row>
                <Row style={{ width: "100%", marginLeft: '18rem', marginTop:'20px' }}>
    <Col>
        <DatePicker allowClear={false} defaultValue={moment(time, "YYYY/MM")} onChange={(date, datestring) => {
           setFeedbackDate(datestring);
           feedbackHandler(feedbackId,datestring);
        }} picker="month" />
    </Col>

                </Row>

    {loader?<Row justify="center"><Spin size="large" style={{ alignSelf: "center", minHeight:"10vh",margin:'5vh 0 0 10vw'}} /></Row>:
    feedbackData?.length===0?<Row>No Data Found</Row>:
    <Row className="scroll" style={{display:'flex',flexDirection:'row',gap:10, maxHeight:'500px',overflowY:'scroll',overflowX:'unset' }}>
    {feedbackData?.map((val,index)=>{
        const emoji=["","😒","😧",'🤨',"😃","😍"];
            return(

            <div style={{width:'100%'}}>
                    <div style={{backgroundColor:"#FAFAFA"}}>{val?.question}</div>
                    <div>{val?.data}</div>
            </div>
            )})}

    </Row>}

        </Row>
    </Modal>
</Row>

    )
    
}
export default Associateview;