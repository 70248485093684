import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Layout, Card, Button, Row, Col, Tabs, DatePicker, Spin, Typography } from 'antd';
import { ConsoleSqlOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import brigoshalogo from '../../Images/brigoshalogo.png'
import { HomeOutlined, ClusterOutlined, UsergroupAddOutlined, AppstoreOutlined, LogoutOutlined } from '@ant-design/icons';
import './Dashboard.css';
import classes from "./Dashboard.css"
import Graphs from './Graphs';
import Recommendations from './Recommendations';
import Api from 'services/Api';
import { log } from '@antv/g2plot/lib/utils';
import moment from 'moment';
import "../../App.css"
// import { useSelector } from 'react-redux';

function Dashboard(props) {
    const { Content } = Layout;
    const onChange = (key) => {
        // console.log(key);
    };
    const [token, setToken] = useState(localStorage.getItem("token") ? localStorage.getItem("token") : null)

    const [dashboarData, setDashboardData] = useState([]);
    const [depHead, setDepHead] = useState("")
    const [teams, setTeams] = useState(0)
    const [graph, setGraph] = useState(true)
    const [graphData, setGraphData] = useState([])
    const [recmData, setRecmData] = useState([])
    const [rec, setRec] = useState(false)
    let time = moment().format("YYYY-MM")
    const [date, setDate] = useState(time)
    const [deptId, setDeptId] = useState(null)
    const [reload, setReload] = useState(false);
    const [totalFeedbackGiven, setTotalFeedbackGiven] = useState(0)
    const [total, setTotal] = useState(0);
    const [loader, setLoader] = useState(false);
    const [loader1, setLoader1] = useState(false)
    const [recDeptName, setRecDeptName] = useState(" ")
    const [deptName, setDeptName] = useState()
    const [deptClick, setDeptClick] = useState(null)

    // console.log("zzzzzzzz",time)
    const getRecData = (date, department) => {
        let a = date.split("-");
        setLoader(true);
        Api.get('/dashboard/recommendation').params({ month: a[1], year: a[0], departmentId: department })
            .send((res, err) => {
                setLoader(false)
                // console.log('resREC1', res, 'resREC2', err)
                setRecmData(res)

            })
    }
    const getGraph = (date, department) => {
        let a = date.split("-");
        setLoader(true)
        Api.get('/dashboard/graph').params({ month: a[1], year: a[0], departmentId: department })
            .send((res, err) => {
                setLoader(false)
                // console.log('resGraph1', res, 'resGraph2', err)
                setGraphData(res)
            })
    }

    useEffect(() => {
        if (localStorage.getItem("token")) {
            // console.log("andha sala",props);
            let a = date.split("-");
            setLoader1(true)
            setLoader(true)
            // console.log("zzzzz",{ month: a[1], year: a[0]})


            Api.get("/dashboard/dashBoard").params({ month: a[1], year: a[0] })
                .send((response, error) => {

                    // console.log("res1", response, "res2", error)
                    let sum = 0;
                    let sum1 = 0;
                    response?.forEach((val) => {
                        sum = sum + val?.numberOfPeopleGivenFeedBack;
                        sum1 += val?.totalPeople;
                    })
                    setTotalFeedbackGiven(sum);
                    setTotal(sum1)
                    setDashboardData(response)
                    setLoader1(false)
                    setLoader(false)
                    setRecDeptName(response)
                })
        }

    }, [reload, date, localStorage.getItem("token")])

    useEffect(() => {
        // console.log(localStorage.getItem("token"))
        if (localStorage.getItem("token")) {
            // console.log("andha sala","dashboard");
            let a = date.split("-");
            setLoader(true)
            Api.get('/dashboard/recommendation').params({ month: a[1], year: a[0] })
                .send((res, err) => {
                    // console.log('resREC1', res, 'resREC2', err)
                    setRecmData(res)
                })
            Api.get('/dashboard/graph').params({ month: a[1], year: a[0] })
                .send((res, err) => {
                    // console.log('resGraph1', res, 'resGraph2', err)
                    setGraphData(res)
                })
            setLoader(false)
        }
    }, [reload, localStorage.getItem("token")])

    const clickHandle = (obj) => {
        setDepHead(obj.headOfDepartmentName)
        setTeams(obj.numberOfTeams)
    }
    const deptHandle = (dept) => {
        setDeptName(dept.department)
        // console.log('jjjj', dept)
    }



    const bodyStyle = {
        background: '#F7F7F7',
        width: "calc(100% + 30px)",
        height: "calc(100% + 30px)",
        
        padding: 60,


    }

    return (
        <Row className='dashboard' style={bodyStyle}>

            <Row offset={1} style={{  width: '100%' }}>

                <Col span={18} >
                    <div style={{ width: '100%',  padding: '40px 0px 40px 40px', backgroundColor: 'white' }}>
                        <h3 style={{ font: 'normal normal bold 24px/32px Roboto', color: '#111111' }}>Department Feedbacks</h3>
                        {loader1 ? <Row justify="center" style={{display:'flex', alignItems:'center',justifyContent:'center'}}><Spin size="large" style={{ alignSelf: "center", minHeight: "10vh" }} /></Row> :
                            <Row className='scroll' style={{ width: '100%', display: 'flex', gap: 30, font: 'normal normal bold 24px/32px Roboto', maxHeight: '190px', overflowY: 'scroll' }} >

                                {dashboarData?.map((val, index) => {
                                    let bcolor = deptClick === val?.department_id ? "#E3F2FF" : "#FBFBFB"
                                    let tcolor = val?.department_id === deptClick ? "#0086FF" : ""

                                    return (
                                        <Col span={5} >
                                            <div block onClick={() => {
                                                setDeptClick(val?.department_id)
                                                clickHandle(val);
                                                setDeptId(val?.department_id);
                                                getGraph(date, val?.department_id);
                                                getRecData(date, val?.department_id);
                                            }} style={{ display: 'flex', gap: 12, justifyContent:'left', backgroundColor: bcolor, color: tcolor, boxShadow: "0px 2px 5px #00000014", padding: '5px 8px 5px 8px', cursor: 'pointer', alignItems: 'center' ,maxWidth:'160px'}}>
                                                <Typography.Text ellipsis={{tooltip:true}} style={{ font: 'normal normal normal 16px/21px Roboto',maxWidth:90 }}>{val?.department}</Typography.Text>
                                                <div style={{ display: 'flex', alignItems: 'center' }}><div style={{ font: 'normal normal bold 24px/32px Roboto' }}>{val?.numberOfPeopleGivenFeedBack}</div><div style={{ font: 'normal normal bold 16px/21px Roboto' }}>/{val?.totalPeople}</div></div></div>
                                        </Col>
                                    )


                                })}

                            </Row>}

                    </div>
                </Col>

                <Col   offset={1} style={{ display:'flex',flexDirection:'column',gap:"40px",}}>
                   
                        <div style={{ width: '200px', height: '142px',display:'flex',flexDirection:'column',justifyContent:'center', gap:'10px' ,backgroundColor:'white', alignItems:'center',}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center' }}><div style={{font: "normal normal bold 36px/48px Roboto"}}>{totalFeedbackGiven}</div><div style={{font:"normal normal bold 24px Roboto"}}>{"/"+total}</div></div>
                            <div className='totalFeedback2'> Feedback from all  Associates</div>
                        </div>
                 
                    
                    <div style={{ width: '200px', height: '142px', display:'flex',flexDirection:'column',justifyContent:"center", gap:'16px',backgroundColor:'white',padding:'0px 16px' }} >
                        <div>
                            <div style={{font: 'normal normal bold 18px/24px Roboto', color: '#111111', }}>Department Head:</div>
                            <Typography.Text ellipsis={{tooltip:true}} style={{font: 'normal normal normal 16px/24px Roboto', color: '#111111', maxWidth:"160px",}}>{depHead!==""?depHead:"-"}</Typography.Text>
                            </div>
                    <div>
                                <div style={{ font: 'normal normal bold 18px/24px Roboto', color: '#111111' }}>No of Teams:</div>
                                <div style={{font: "normal normal normal 16px/24px Roboto"}}>{teams}</div>
                                </div>
                    </div>
                   
                </Col>
            </Row>

            <Row style={{ width: '100%', marginLeft: '1rem', minHeight: '100%' }}>
                <Col span={18} style={{ width: '100%' }}>
                    <Row style={{ width: "100%", display: 'flex' }}>
                        <Col span={6} offset={18}>

                            <DatePicker allowClear={false} placement="bottomLeft" defaultValue={moment(date, "YYYY/MM")} onChange={(date, datestring) => {
                                setDate(datestring);
                                getGraph(datestring, deptId);
                                getRecData(datestring, deptId);

                            }} picker="month" style={{ color: 'blue', marginTop:'30px' }} />


                        </Col>

                    </Row>
                    <Row style={{ maxHeight: '100%', width: '100%', }}>

                        <Tabs defaultActiveKey="1" style={{ width: "100%", }} >
                            <Tabs.TabPane  tab={<div className='tab' style={{ font: "normal normal bold 24px/32px Roboto", color:`${graph?"#000000":"#A0A0A0"}`  }} onClick={()=>{setGraph(true);}}>Graphs</div>} key="1" >
                                {loader ? <Row justify="center"><Spin size="large" style={{ alignSelf: "center", minHeight: "10vh" }} /></Row> : <Graphs data={graphData} deptId={deptId} date={date} ></Graphs>}
                            </Tabs.TabPane>
                            <Tabs.TabPane  className='scroll' style={{ maxHeight: '600px', overflowY: 'scroll', }} tab={<div className='tab' style={{ font: "normal normal bold 24px/32px Roboto",color:`${graph?"#A0A0A0":"#000000"}` }} onClick={()=>{setGraph(false);  }}>Recommendations</div>} key="2">
                                {loader ? <Row justify="center"><Spin size="large" style={{ alignSelf: "center", minHeight: "10vh", }} /></Row> : <Recommendations data={recmData} deptData={deptName} deptRes={recDeptName} />}
                            </Tabs.TabPane>
                        </Tabs>

                    </Row>



                </Col>
                <Col className='scroll' span={4} offset={1} style={{ marginTop: 60, maxHeight: '640px', overflowY: 'scroll' }}>
                    <Button type="text" block style={{ textAlign: 'left', backgroundColor:`${deptClick===null?"#E3F2FF":""}`, color:`${deptClick===null?"#0086FF":""}` }} onClick={() => { setReload(!reload); setDeptId(null); setDeptClick(null);setDepHead("") }} >
                        All Department
                    </Button>
                    {dashboarData?.map((val, id) => {
                        let bcolor = val?.department_id === deptClick ? "#E3F2FF" : ""
                        let tcolor = val?.department_id === deptClick ? "#0086FF" : ""
                        return (
                            <Button type='text' block onClick={() => {
                                setDeptClick(val?.department_id);
                                deptHandle(val);
                                setDeptId(val?.department_id);
                                getGraph(date, val?.department_id);
                                getRecData(date, val?.department_id);
                                setDepHead(val?.headOfDepartmentName)
                            }} style={{ textAlign: 'left', backgroundColor: bcolor, color: tcolor }}>{val?.department}</Button>
                        )

                    })}
                </Col>
            </Row>
        </Row>


    )
}

export default Dashboard;