import React, { Fragment, useEffect, useState } from 'react';
import { Layout, Row, Col, Button, Tabs, DatePicker, Card, Tree, Spin } from 'antd';
import './Departments.css'
import Graphs from '../Dashboard/Graphs';
import Associates from '../Associates/Associates';
import Api from 'services/Api';
import { HomeOutlined, ClusterOutlined, UsergroupAddOutlined, AppstoreOutlined, LogoutOutlined } from '@ant-design/icons';

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import moment from 'moment';
import Graphview from './Graphview';
import Associateview from './Associateview';
import qs from 'qs';
import "../../App.css"
function Departments() {
    const { Header, Sider, Content } = Layout;
    const [graph, setGraph] = useState(true);
    const [associateView, setAssociateView] = useState(false);
    const time=moment().format("YYYY-MM")
    const [date, setDate] = useState(time)
    const [departmentData, setDepartmentData] = useState([])
    const [deptId, setDeptId] = useState(null)
    const [reload,setReload]=useState(false)
    const [search,setSearch]=useState("");
    const [graphViewData, setGraphViewData] = useState([]);
    const [associateViewData, setAssociateViewData] = useState([])
    const [feedbackDetails, setFeedbackDetails] = useState([])
    const [deptVal,setDeptVal]=useState({});
    const [loader,setLoader]=useState(false)
    const[deptBtnChange,setDeptBtnChange] = useState(null)
    const [givenFeedbackNumber,setGivenFeedbackNumber]=useState(null)
    const [tableParams, setTableParams] = useState({
        pagination: {
          current: 1,
          pageSize: 10,
        },
      });

    const handleTableChange = (pagination) => {
        setTableParams({
          pagination,
         
        });
      };
      const getRandomuserParams = (params) => ({
        limit: params.pagination?.pageSize,
        page: params.pagination?.current,
        
      });
      const [loading, setLoading]=useState(false) 
    const onChange = (key) => {
        // console.log(key);
    };

    const getGraphView = (date, department) => {
        setLoader(true)
        let a = date.split("-");
        let data = { month: a[1], year: a[0], departmentId: department }
        Api.get("/departments/graph").params(data).send((res) => {
            setLoader(false)
            setGraphViewData(res?.responseData);
            setGivenFeedbackNumber(res?.numberOfPeopleGivenFeedBack);

        })
    }
    const getAssociateViewData = (date, department) => {
        

        let a = date.split("-")
        // console.log("kkk", department)
        let data = { month: a[1], year: a[0], departmentId: department, search:search }
        Api.get(`/departments/associateDetails/?${qs.stringify(getRandomuserParams(tableParams))}`).params(data).send((res) => {
            
            setAssociateViewData(res?.responseData);
            setTableParams({
                ...tableParams,
                pagination: {
                  ...tableParams.pagination,
                  total: res?.meta?.totalItems,
                  showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} members`
                },
              });
        })
    }

    // const getFeedbackDetailsData = (date, department) => {
    //     let a = date.split("-")
    //     let data = { month: a[1], year: a[0], departmentid: 1, empid: 2 }
    //     Api.get("/departments/feedBackDetail").params(data)
    //         .send((res) => {
    //             console.log("feed", res);
    //             setFeedbackDetails(res)

    //         })
    // }
const [count,setCount]=useState(0)
    
  useEffect(()=>{
    setCount(count+1);
    if(count>1)
    {
       getAssociateViewData(date,deptId); 
    }
   
  },[search, JSON.stringify(tableParams)])

    useEffect( () => {
        setLoader(true)
        let a = date.split("-")
         Api.get("/departments/departmentDetails").params({month:a[1],year:a[0],}).send((res) => {
            setDepartmentData(res)

        })

        Api.get(`/departments/associateDetails/?${qs.stringify(getRandomuserParams(tableParams))}`).params({ month: a[1], year: a[0]}).send((res) => {

            setAssociateViewData(res?.responseData);
            setTableParams({
                ...tableParams,
                pagination: {
                  ...tableParams.pagination,
                  total: res?.meta?.totalItems,
                  showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} members`
                },
              });
        })
        
        Api.get("/departments/graph").params({ month: a[1], year: a[0],}).send((res) => {
            setLoader(false);
            setGraphViewData(res?.responseData);
            setGivenFeedbackNumber(res?.numberOfPeopleGivenFeedBack);

        })
        
}, [reload])



    return (

        <Row style={{ width: '100%', height:'100%' }}>
            <h2 style={{ marginLeft: '2rem', marginTop: '3rem' }}>Departments</h2>
            <Row style={{ width: '100%', marginLeft: '10px',minHeight:'700px' }}>
                <Col className='scroll' span={3} style={{borderRight:'1px solid lightgrey',padding:'1rem',width:'100%',maxHeight:'700px', overflowY:"scroll"}}  >
                    <Button block type="text"  style={{backgroundColor:`${deptBtnChange?"#ffffff":"#E3F2FF"}`,}} onClick={()=>{ setDeptBtnChange(null);setSearch("");setReload(!reload);setDeptVal({});setDeptId(null)}}>
                        All
                    </Button>
                    {departmentData?.map((val, id) => {
                        let bgColor=val?.departmentId===deptBtnChange?"#E3F2FF":"";
                        let tcolor=val?.departmentId===deptBtnChange?"#0086FF":"";
                        return (
                            <Button  type='text' block onClick={() => {
                                setDeptBtnChange(val?.departmentId)
                                getGraphView(date, val?.departmentId);
                                setDeptVal(val);
                                getAssociateViewData(date, val?.departmentId);
                                setDeptId(val?.departmentId);
                                setSearch("");
                            }} style={{backgroundColor:bgColor, color:tcolor}}>{val?.department}</Button>
                        )
                    })}
                </Col>
                <Col span={20} style={{marginLeft:'30px'}}>
            {Object.keys(deptVal).length===0?"":<Row style={{  height: '60px', marginBottom: '2rem', backgroundColor:'white',padding:'1rem',width:'93%' }}>
                              
                              <Col span={4}>{deptVal?.department}</Col >
                              <Col span={15} offset={1} style={{ display: 'flex'}}>
                              <div style={{width:'100%',display:'flex'}}><h4>Department Head: </h4><p >{deptVal?.headOfDepartmentName}</p></div>
                              <div style={{width:'100%',display:'flex'}}><h4>No Of Teams: </h4> <p > {deptVal?.numberOfTeams}</p></div>
                              </Col >
                              <Col span={3} offset={1}><p style={{display:'flex',gap:'4px',color:'#0086FF', fontSize:'16px'}} ><h4>Associates: </h4> {givenFeedbackNumber +"/"+ deptVal?.totalPeople}</p></Col >
                      
                  </Row>}

                {/* <Row style={{  height: '60px', marginBottom: '2rem', backgroundColor:'white',padding:'1rem',width:'93%' }}>
                              
                            <Col span={4}>{departmentData[0]?.department}</Col >
                            <Col span={10} offset={3} style={{ display: 'flex', gap: '20px' }}>
                                <p style={{display:'flex' ,gap:'4px'}}><h4>DepartmentHead:</h4> {departmentData[0]?.headOfDepartmentName}</p>
                                <p style={{display:'flex',gap:'4px'}}><h4>No Of Teams:</h4> {departmentData[0]?.numberOfTeams}</p>
                            </Col >
                            <Col span={5} offset={2}><p><h4>Associates:</h4></p></Col >
                    
                </Row> */}
                    <Row style={{marginRight:'5rem'}}>
                    <Col span={4} offset={20} style={{ display:'flex'}}>
                            <DatePicker allowClear={false} defaultValue={moment(time, "YYYY/MM")} onChange={(date, datestring) => {
                                setDate(datestring);
                                getGraphView(datestring, deptId) 
                                getAssociateViewData(datestring, deptId)
                            }} picker="month" style={{ height: '20px', }} />
                            
                        </Col>

                    </Row>

                    <Row style={{width:'100%'}}>
                        
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane tab="Graphs View" key="1">
                                {loader?<Row justify="center"><Spin size="large" style={{ alignSelf: "center", minHeight:"10vh",margin:'5vh 0 0 10vw'}} /></Row>:<Graphview data={graphViewData} date={date} deptId={deptId} />}   
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Associate View" key="2">
                                {loader?<Row justify="center"><Spin size="large" style={{ alignSelf: "center", minHeight:"10vh",margin:'5vh 0 0 10vw'}} /></Row>:
                                    <Associateview data={associateViewData} date={date} setSearch={setSearch} tableParams={tableParams} handleTableChange={handleTableChange} loading={loading}  />}
                                </Tabs.TabPane>
                                    
                            </Tabs>

                                               
                            
                        
                        {/* <Col span={4} >
                            <Button type='text' block 
                             onClick={() => { setGraph(true); setAssociateView(false) }}
                              >Graphs View</Button></Col>
                        <Col span={4} ><Button  type='text' block 
                        onClick={() => { setGraph(false); setAssociateView(true) }}>Associate View</Button></Col> */}
                        {/* <Col span={6} style={{ display:'flex'}}><LeftOutlined />
                            <DatePicker allowClear={false} defaultValue={moment(time, "YYYY/MM")} onChange={(date, datestring) => {
                                setDate(datestring);
                                graph ? getGraphView(datestring, dept) : getAssociateViewData(datestring, dept)
                            }} picker="month" style={{ height: '20px', }} />
                            <RightOutlined />
                        </Col> */}
                    </Row>
                    {/* <Row style={{ width: '100%'}}>
                        <Col >
                            {graph && <Graphview data={graphViewData}></Graphview>}
                            {associateView && <Associateview data={associateViewData}></Associateview>}
                        </Col>
                    </Row> */}

                </Col>
            </Row>
        </Row>


    )
}

export default Departments